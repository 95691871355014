
import { Component, Vue } from "vue-property-decorator";
// import { Field } from 'vant';
// import { accountLoginRequest } from "@/service/api";
import { request } from "@/service/libs/index";
import { Toast } from "vant";

@Component({
  name: "PerfectPayType",
  components: {
    // HelloWorld,
    // Field,
  },
})
export default class PerfectPayType extends Vue {
  private payList = {
    icon: require("@/assets/paysuccess-icon.png"),
    name: "Perfect Money",
    min: 1,
    max: 2,
    moneyMin: 10,
    moneyMax: "50,000",
  };

  private money: any = "";
  private perfectResult: any = {};
  private perfectOrderResult: any = {};
  private perfectActionUrl: string = "https://perfectmoney.com/api/step1.asp";
  // 下单
  private async handleBuy() {
    Toast.loading({
      message: this.$t("loading") as string,
      forbidClick: true,
      loadingType: "spinner",
      duration: 0,
    });
    let amount: number = Number(this.money );
    let result: any = await request({
      method: "GET",
      path: "/v1/api/pay/placeOrder/bankwire?amount=" + amount,
    });
    Toast.clear();
    console.log(result);
    this.perfectOrderResult = result;
    if (this.perfectOrderResult && this.perfectOrderResult.orderNo) {
      // 保存订单号在本地，已供后续成功和失败页面调用
      localStorage.setItem("telegraphic-orderId", result.orderNo);
      localStorage.setItem("telegraphic-info", JSON.stringify(result));
      setTimeout(() => {
        this.$router.push({ name: "telegraphic-list" });
      }, 200);
    }
  }
  // 查询单个支付渠道
  private async getPayInfoByChannelId() {
    let result: any = await request({
      method: "GET",
      path: "/v1/api/pay/channel/info?channelId=100003",
    });
    console.log(result);
    this.perfectResult = result;
    if (result?.data) {
      this.money = result.data.channelId;
    }
  }

  mounted() {
    this.getPayInfoByChannelId();
    // setTimeout(() => {
    //   localStorage.setItem(
    //     "telegraphic-info",
    //     JSON.stringify({
    //       orderNo: "12121212",
    //       money: 1212,
    //       bankName: "JPMorgan Chase BANK N.A., New York",
    //       transferCode: "0122334",
    //       bankAddress: "270 Park Ave. New York, 10017, NY, USA",
    //       accountName: "VSTAR AUS CAPITAL CLIENT AC 1",
    //       accountAddress: "270 Park Ave. New York, 10017, NY, USA",
    //       accountBankCard: "7435345",
    //       swift: "CHASUS33",
    //     })
    //   );
    //   this.$router.push({ name: "telegraphic-list" });
    // }, 200);
  }
}
