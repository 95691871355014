declare let __vstar_js_channel__: {
  postMessage(message: string): void;
};

/// 版本号，请勿修改
const version = "v1";
const isVstarAppEnvironment = typeof __vstar_js_channel__ !== "undefined";

function sendMessage(message: object) {
  if (!isVstarAppEnvironment) {
    return;
  }

  let messageString = null;
  try {
    messageString = JSON.stringify(message);
  } catch (e) {
    console.error("[vstarjs]序列化JSON消息失，请传送正确的JSON格式数据：", e);
  }
  if (messageString != null) {
    __vstar_js_channel__.postMessage(messageString);
  }
}

function callMethodWithoutReturn(method: string, params?: any) {
  sendMessage({
    version,
    method: method,
    params: params,
  });
}

let count = 0;

function generateCallbackIDs() {
  const id = count++;
  const time = Date.now();
  return [
    `__vstarjs_channel_resolve_${id}_${time}__`,
    `__vstarjs_channel_reject_${id}_${time}__`,
  ];
}

function setCallback(callbackId: string, callback: Function | null) {
  (window as any)[callbackId] = callback;
}

function callMethodWithReturn<T>(method: string, params?: any): Promise<T> {
  return new Promise((resolve, reject) => {
    const [resolveID, rejectID] = generateCallbackIDs();
    const clear = () => {
      setCallback(rejectID, null);
      setCallback(resolveID, null);
    };
    setCallback(resolveID, (result: { data: T }) => {
      clear();
      resolve(result.data);
    });
    setCallback(rejectID, (reason: { data: any }) => {
      clear();
      reject(reason.data);
    });
    sendMessage({
      version,
      method,
      params,
      resolve: resolveID,
      reject: rejectID,
    });
  });
}

/**
 * 设置标题
 * @param title 标题内容
 */
export function setTitle(title: string) {
  callMethodWithoutReturn("setTitle", title);
}

/**
 * 分享文本
 * @param options
 * @param options.text 分享内容
 * @param options.subject 分享主题 (可选)
 */
export function shareText(options: {
  text: string;
  subject?: string;
}): Promise<{
  status: "success" | "dismissed" | "unavailable";
}> {
  return callMethodWithReturn("shareText", options);
}

/**
 * 分享base64图片
 * @param options
 * @param options.image base64格式，如：data:image/png;base64,xxxxx...
 * @param options.text 分享内容 (可选)
 * @param options.subject 分享主题 (可选)
 */
export function shareBase64Image(options: {
  image: string;
  text?: string;
  subject?: string;
}): Promise<{
  status: "success" | "dismissed" | "unavailable";
}> {
  return callMethodWithReturn("shareBase64Image", options);
}

/**
 * 是否已登录
 * @returns boolean
 */
export function hasLogged(): Promise<boolean> {
  return callMethodWithReturn("hasLogged");
}

/**
 * 获取多语言代码，返回值如：en, zh, zh-TW 等
 * @returns string
 */
export function getLocale(): Promise<string> {
  return callMethodWithReturn("getLocale");
}

/**
 * 发起请求。
 * 请求成功会返回成功结果，
 * 请求出现异常(不论是网络原因还是服务返回错误状态)，APP会对异常做处理，
 * webview不需要做任何弹窗处理，会进入promise的reject流程
 * @param options
 * @param options.method 请求方法: "GET" 或 "POST"
 * @param options.path 请求链接的相对路径，不需要带域名，例如：/v1/api/mt5/symbol/hot
 * @param options.showLoading 是否展示loading状态 (可选)
 * @param options.data 请求数据，json格式，只在 "POST" 方法时生效 (可选)
 * @returns 返回数据格式可以根据泛型自定义
 */
export function request<T, U extends object>(options: {
  method: "GET" | "POST";
  path: string;
  showLoading?: boolean;
  data?: U;
}): Promise<T> {
  return callMethodWithReturn("request", options);
}

/**
 * 打开新的WebView，当该WebView关闭时返回页面结果
 * 1. 如果新的WebView里JS通过closeWebView方法关闭并返回pageResult，promise将resolve该pageResult;
 * 2. 如果新的WebView被用户手动关闭，promise将resolve null;
 * 3. 一个页面只能打开一个app页面或一个webView页面，比如打开一个webView或app页面后该页面没有被关闭，
 * 再次调用会被reject；
 * 4. url未传会reject。
 * @param options 参数选项
 * @param options.url 新的webView URL,必须是绝对路径: https://baidu.com/a/b/c
 * @param options.title 标题（可选）
 * @param options.backgroundColor 页面背景颜色（可选）
 * @param options.isLoginRequired 是否需要登录态才能打开（可选）
 */
export function openWebView<T>(options: {
  url: string;
  title?: string;
  backgroundColor?: string;
  isLoginRequired?: boolean;
}): Promise<T | null> {
  return callMethodWithReturn("openWebView", options);
}

/**
 * 关闭当前WebView，并可选返回页面结果
 * 比如充值成功后可以调用该方法把充值成功状态告诉app
 * 页面结果必须使用json格式
 * @param pageResult
 */
export function closeWebView<T extends object>(pageResult?: T) {
  callMethodWithoutReturn("closeWebView", pageResult);
}

/**
 * 打开app页面，当该页面关闭会返回页面结果
 * 一个页面只能打开一个app页面或一个webView页面，比如打开一个webView或app页面后该页面没有被关闭，
 * 再次调用会被reject。
 * pageRoute未传会reject。
 * @param options
 * @param options.pageRoute app页面路由
 * @param options.queryParameters app页面参数
 */
export function openAppPage<T>(options: {
  pageRoute: string;
  queryParameters?: { [key: string]: any };
}): Promise<T | null> {
  return callMethodWithReturn("openAppPage", options);
}

/**
 * 打开alert弹窗
 * @param options
 * @param options.content 弹窗内容
 * @param options.title 弹窗标题（可选）
 * @param options.buttonLabel 按钮文案（可选）
 */
export function showAlert(options: {
  content: string;
  title?: string;
  buttonLabel?: string;
}): Promise<void> {
  return callMethodWithReturn("showAlert", options);
}

/**
 *
 * @param options
 * @param options.content toast消息
 * @param options.duration 展示时间（可选），单位毫秒，默认2000毫秒（2秒）
 */
export function showToast(options: {
  content: string;
  duration?: number;
}): Promise<void> {
  return callMethodWithReturn("showToast", options);
}

/**
 *
 * @param options
 * @param options.content 提示内容
 * @param options.title 提示标题（可选）
 * @param options.cancelLabel 取消按钮文案（可选）
 * @param options.confirmLabel 确认按钮文案（可选）
 * @returns 返回true或false的promise
 */
export function showConfirm(options: {
  content: string;
  title?: string;
  cancelLabel?: string;
  confirmLabel?: string;
}): Promise<boolean> {
  return callMethodWithReturn("showConfirm", options);
}
